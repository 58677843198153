const imageLink = (path) => {
  const baseLink =
    process.env.NODE_ENV === "production"
      ? "https://demo.vitap.locaro.in/api/vitap"
      : "http://localhost:5100/api/vitap";

  return baseLink + path;
};

export default imageLink;
