import { useAuth } from "./useAuth";

const Authentication = ({ children }) => {
  const { user, validate } = useAuth();

  if (user.gotUser) {
    return children;
  } else {
    console.log("VALIDATING...");
    validate();
  }
};

export default Authentication;
