import React, { useState, useEffect } from "react";

// import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore from "swiper";
// import { Autoplay, Pagination } from "swiper/modules";
// import "swiper/modules/pagination/pagination.min.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import { Link } from "react-router-dom";

import agent from "../../agent";
import "swiper/css";
import { useLoading } from "../../Utils/useLoading";
import imageLink from "../../Utils/Image";

function PromotionSlider() {
  const [promotions, setPromotions] = useState([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    agent.home
      .promotions()
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setPromotions(res.data.promotions);
          setLoading(false);
          console.log(promotions);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div className="promotion-div">
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="promotion"
      >
        {promotions[0] ? (
          promotions.map((promotion) => {
            return (
              <SwiperSlide
                key={promotion.promotion_uid}
                className="promotion__slide"
              >
                <Link to={`/shop/${promotion.shop_uid}`}>
                  <img
                    src={imageLink(
                      `/images/promotions/${promotion.promotion_image}`
                    )}
                    alt=""
                    className="locaro-box-shadow"
                  />
                </Link>
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide className="promotion__slide">
            <img
              src={`${
                process.env.NODE_ENV === "production"
                  ? "https://www.locaro.in/api"
                  : "http://localhost:5100/api"
              }/vitap/images/promotions/banner_placeholder.jpg`}
              alt=""
            />
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}

export default PromotionSlider;
