import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import TextField from "@mui/material/TextField";

import "./styles.css";
import agent from "../../agent";
import { useLoading } from "../../Utils/useLoading";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";

function ResetPasswordPage() {
  const { id } = useParams();
  const [email, setEmail] = useState("");
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(id);
    setLoading(true);
    agent.auth
      .changePassword(id, e.target.password.value)
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          floatingMessage("Success", res.data.message, "success");
          navigate("/login");
        } else {
          setLoading(false);
          floatingMessage("Error", res.data.message);
          navigate("/forgot-password");
        }
      })
      .catch((err) => {
        setLoading(false);
        floatingMessage("Error", "Something went wrong.");
        navigate("/forgot-password");
      });
  };

  useEffect(() => {
    setLoading(true);
    console.log(id);
    agent.auth
      .getResetRequest(id)
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          setEmail(res.data.email);
        } else {
          setLoading(false);
          floatingMessage("Error", res.data.message);
          navigate("/forgot-password");
        }
      })
      .catch((err) => {
        setLoading(false);
        floatingMessage("Error", "Something went wrong.");
        navigate("/forgot-password");
      });
  }, []);

  useEffect(() => {
    document.title = "Reset Password | Locaro";
  }, []);
  return (
    <>
      <div className="register-login__top-div">
        <h1 className="logo-text">
          Locaro<span>VITAP</span>
        </h1>
        <span className="header">Reset Password</span>
      </div>
      <form className="register-login__forms" onSubmit={submitHandler}>
        <TextField
          variant="outlined"
          label="New Password"
          name="password"
          className="locaro-input locaro-box-shadow"
          type="password"
          fullWidth
        />
        <p>
          Your new password should be at least 8 characters long. Use upper and
          lower case letters, numbers, and symbols.
        </p>
        <button className="register-login__btn">Change Password</button>
        <button className="cancel-btn">
          Cancel
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
              <path
                id="Vector"
                d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                fill="#B90504"
              />
            </g>
            <defs>
              <clipPath id="clip0_3_1143">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </form>
    </>
  );
}

export default ResetPasswordPage;
