import React, { useContext } from "react";

import "./styles.css";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context";
import agent from "../../agent";
import imageLink from "../../Utils/Image";
import { useLoading } from "../../Utils/useLoading";

function SmallProductCard({ product }) {
  const { getCart } = useContext(CartContext);
  const { setLoading } = useLoading();

  const onAdd = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.cart
      .add(product.product_uid)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onDecrease = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.cart
      .decrease(product.product_uid)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Link
      className="small-product-card"
      to={`/products/${product.product_uid}`}
    >
      <div className="image-div">
        <img src={imageLink(`/images/products/${product.product_images[0]}`)} />
      </div>
      <div className="text-div">
        <span className="product-name text-2-lines">
          {product.product_name}
        </span>
        <span className="subtext">{product.subtext}</span>
        <div className="bottom-div">
          <div className="price-div">
            {product.price === product.product_mrp ? null : (
              <span className="old-price">₹{product.product_mrp}</span>
            )}
            <span className="current-price">₹{product.price}</span>
          </div>
          {!product.quantity ? (
            <button className="add-button" onClick={onAdd}>
              Add
            </button>
          ) : (
            <div className="quantity-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
                onClick={onDecrease}
              >
                <path d="M200-450v-60h560v60H200Z" />
              </svg>
              <div>{product.quantity}</div>
              <svg
                onClick={onAdd}
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
              </svg>
            </div>
          )}
        </div>
      </div>
      {product.price === product.product_mrp ? null : (
        <div className="percentage-div">
          {Math.floor(100 - (product.price * 100) / product.product_mrp)}% off
        </div>
      )}
    </Link>
  );
}

export default SmallProductCard;
