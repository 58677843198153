import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import agent from "../../agent";
import { Link } from "react-router-dom";

import $ from "jquery";

import "./styles.css";

const OrderCard = ({ order }) => {
  useEffect(() => {
    if (order.delivery_status === "Accepted") {
      $(".order-card__lower").find("span").addClass("accepted");
    } else if (order.delivery_status === "Pending") {
      $(".order-card__lower").find("span").addClass("pending");
    } else if (order.delivery_status === "On Route") {
      $(".order-card__lower").find("span").addClass("on-route");
    } else if (order.delivery_status === "Declined") {
      $(".order-card__lower").find("span").addClass("declined");
    }
  }, []);

  return (
    <div className="order-card locaro-box-shadow" key={order.delivery_uid}>
      <div className="order-card__upper text-2-lines">
        <h4>{Array.from(order.shops, (shop) => shop.shop_name).join(", ")}</h4>
        <h5>₹{order.paid}</h5>
      </div>
      <div className="order-card__lower">
        <span>{order.delivery_status}</span>
        <Link to={`/order/${order.delivery_uid}`}>
          <h4>Details</h4>
          <div className="details-arrow-button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
                <path
                  id="Vector"
                  d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                  fill="#B90504"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_1143">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OrderCard;
