import React from "react";
import { Outlet } from "react-router-dom";
import TabBar from "../TabBar";

function AppLayout() {
  return (
    <>
      <Outlet />
      <TabBar />
    </>
  );
}

export default AppLayout;
