import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";

import PromotionSlider from "./PromotionSlider";
import CategorySlider from "./CategorySlider";

import "./styles.css";
import ShopsNearbySlider from "./ShopsNearbySlider";

function HomePage() {
  useEffect(() => {
    document.title = "Locaro | Buy from shops and stores near you";
  }, []);
  return (
    <>
      <Navbar search />
      <div className="home-container">
        <CategorySlider />
        {/* <PromotionSlider /> */}
        <ShopsNearbySlider />
      </div>
    </>
  );
}

export default HomePage;
