import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import agent from "../../agent";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";
import { useLoading } from "../../Utils/useLoading";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const createHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.auth.createResetRequest(e.target.email.value).then((res) => {
      if (res.data.status === "success") {
        setLoading(false);
        floatingMessage("success", res.data.message, "success");
        navigate("/login");
      } else {
        setLoading(false);
        floatingMessage("Reset Error", res.data.message, "error");
      }
    });
  };

  useEffect(() => {
    document.title = "Forgot Password | Locaro";
  }, []);

  return (
    <>
      <div className="register-login__top-div">
        <h1 className="logo-text">
          Locaro<span>VITAP</span>
        </h1>
        <span className="header">Forgot Password</span>
      </div>
      <form className="register-login__forms" onSubmit={createHandler}>
        <p>
          Enter the Student email address associated with your account and we'll
          send you a link to reset your password
        </p>
        <TextField
          variant="outlined"
          label="Student Email"
          name="email"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
        <button className="register-login__btn">Change Password</button>
        <Link to="/login">
          Login
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
              <path
                id="Vector"
                d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                fill="#B90504"
              />
            </g>
            <defs>
              <clipPath id="clip0_3_1143">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </form>
    </>
  );
}

export default ForgotPasswordPage;
