import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import Navbar from "../../components/Navbar";
import agent from "../../agent";
import { Link, useNavigate } from "react-router-dom";

import "./styles.css";
import { useLoading } from "../../Utils/useLoading";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    agent.getProducts
      .orders()
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data);
          setOrders(res.data.orders);
          setLoading(false);
        } else {
          console.log(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        navigate("/profile");
      });
  }, []);

  useEffect(() => {
    document.title = "Orders | Locaro";
  }, []);

  return (
    <>
      <Navbar pageName={"Orders"} back />
      {orders.length !== 0 ? (
        <div className="orders-container">
          {orders.map((order) => (
            <OrderCard order={order} />
          ))}
        </div>
      ) : (
        <div className="empty-cart-container">
          <h3 className="empty-cart-title">No Orders here</h3>
          <span className="empty-cart-text">
            Looks like you haven't ordered anything yet. Start exploring shops
            near you
          </span>
          <button
            className="locaro-btn"
            onClick={() => {
              navigate("/categories");
            }}
          >
            Start Shopping
          </button>
        </div>
      )}
    </>
  );
};

export default OrdersPage;
