import React, { useContext, useEffect, useState } from "react";

import Navbar from "../../components/Navbar";

import "./styles.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CartContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import CartProductCard from "./CartProductCard";
import agent from "../../agent";
import { useLoading } from "../../Utils/useLoading";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";
import { useAuth } from "../../Utils/useAuth";

import empty_cart from "../../assets/Empty_cart.png";

function CartPage() {
  const { cart, cartTotal } = useContext(CartContext);
  const [hostel, setHostel] = useState("MH1");
  const [slot, setSlot] = useState("Morning");
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { validate, user } = useAuth();

  const paymentHandler = async () => {
    console.log(cart);
    setLoading(true);
    validate();
    if (user.gotUser) {
      agent.cart
        .createPayment(cart)
        .then((res) => {
          if (res.data.status === "success") {
            const options = {
              key: "rzp_test_OMEFhq33QJYTBL",
              currency: res.data.currency,
              amount: res.data.amount,
              order_id: res.data.id,
              name: "Locaro",
              description: "Thank you for ordering!",
              // image: "http://localhost:1337/logo.svg",
              handler: function (response) {
                // setLoading(true);
                console.log("res", { response, slot, hostel });
                agent.cart
                  .confirmPayment({ response, slot, hostel })
                  .then((res) => {
                    // setLoading(false);
                    if (res.data.status === "success") {
                      // setOpenPayment(false);
                      setLoading(false);
                      navigate("/");
                      console.log("SUCCESS", res.data);
                    } else {
                      setLoading(false);
                      console.log(res.data);
                      console.log("Something went wrong 1");
                      floatingMessage(
                        "Payment Error",
                        "Something went wrong. Any payments made will be refunded within 5-7 days."
                      );
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    floatingMessage(
                      "Payment Error",
                      "Something went wrong. Any payments made will be refunded within 5-7 days."
                    );
                  });
              },
              // prefill: {
              //   name: userInfo.user_full_name,
              //   email: userInfo.user_email,
              //   phone_number: userInfo.user_phone_number,
              // },
              theme: {
                color: "#b90504",
              },
            };
            const paymentObject = new window.Razorpay(options);

            paymentObject.on("payment.failed", function (response) {
              setLoading(false);
              console.log("Failed to make payment!");
              floatingMessage(
                "Payment Error",
                "Failed to make Payment. Any payments made will be refunded within 5-7 days."
              );
            });

            paymentObject.open();
          } else {
            setLoading(false);
            console.log("Something Went Wrong 2");
            floatingMessage(
              "Payment Error",
              "Failed to make Payment. Any payments made will be refunded within 5-7 days."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          console.log("Something Went Wrong 3");
          floatingMessage("Payment Error", "Please log-in to pay.");
        });
    }
  };

  useEffect(() => {
    console.log("CART", cart);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      console.log("loaded razorpay");
    };
    script.onerror = () => {
      alert("Something went wrong, please check your internet");
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    document.title = "Cart | Locaro";
  }, []);

  return (
    <>
      <Navbar pageName={"cart"} />
      {cart.length !== 0 ? (
        <div className="cart-container">
          <div className="complete-payment">
            <div className="complete-payment__text-section">
              <span className="complete-payment__to-pay">To Pay</span>
              <span className="complete-payment__price">
                ₹{cartTotal.to_pay}
              </span>
            </div>
            <button
              className="complete-payment__button"
              onClick={paymentHandler}
            >
              Complete Payment
            </button>
          </div>
          <div className="locaro-box-shadow filters">
            <div className="filters__card">
              <span>Select Hostel</span>
              <Select
                className="filters__select"
                value={hostel}
                onChange={(e) => {
                  setHostel(e.target.value);
                }}
                //   autoWidth
              >
                <MenuItem value="MH1">MH1</MenuItem>
                <MenuItem value="MH2">MH2</MenuItem>
                <MenuItem value="MH3">MH3</MenuItem>
                <MenuItem value="MH4">MH4</MenuItem>
                <MenuItem value="MH5">MH5</MenuItem>
                <MenuItem value="LH1">LH1</MenuItem>
                <MenuItem value="LH2">LH2</MenuItem>
                {/* <MenuItem value="MH3">MH3</MenuItem> */}
              </Select>
            </div>
            <div className="filters__card">
              <span>Select Slot</span>
              <Select
                className="filters__select"
                value={slot}
                onChange={(e) => {
                  setSlot(e.target.value);
                }}
                //   autoWidth
              >
                <MenuItem value="Morning">Morning (10:00 - 12:00)</MenuItem>
                <MenuItem value="Afternoon">Afternoon (12:00 - 14:00)</MenuItem>
                <MenuItem value="Evening">Evening (14:00 - 16:00)</MenuItem>
              </Select>
            </div>
          </div>
          <div className="locaro-box-shadow cart__products">
            {cart.map((shop) => (
              <div className="shop-div" key={shop.shop_uid}>
                <div className="shop-header">
                  <span>{shop.shop_name}</span>
                </div>
                {shop.products.map((product) => (
                  <CartProductCard
                    product={product}
                    key={product.product_uid}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="locaro-box-shadow">
            <div className="total">
              <div className="item-total">
                <span className="item-total__text">Item Total</span>
                <span className="item-total__price">
                  ₹{cartTotal.item_total}
                </span>
              </div>
              <div className="handling-charges">
                <span className="handling-charges__text">Handling Charges</span>
                <span className="handling-charges__price">
                  ₹{cartTotal.handling_charges}
                </span>
              </div>
              <div className="delivery-charges">
                <span className="delivery-charges__text">Delivery</span>
                <span className="delivery-charges__price">
                  ₹{cartTotal.delivery_charges}
                </span>
              </div>
              <div className="to-pay">
                <span className="to-pay__text">To Pay</span>
                <span className="to-pay__price">₹{cartTotal.to_pay}</span>
              </div>
            </div>
            <div className="saved-bar">
              ₹{cartTotal.saved} saved on this order
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-cart-container">
          <img src={empty_cart} alt="Empty Cart" />
          <h3 className="empty-cart-title">Your Cart is Empty</h3>
          <span className="empty-cart-text">
            Looks like you haven’t added anything to your cart yet
          </span>
          <button
            className="locaro-btn"
            onClick={() => {
              navigate("/categories");
            }}
          >
            Start Shopping
          </button>
        </div>
      )}
    </>
  );
}
export default CartPage;
