import React, { useEffect, useState } from "react";

import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import agent from "../../agent";

function Navbar({ search, pageName, back }) {
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    agent.getProducts.search(searchValue).then((res) => {
      if (res.data.status === "success") {
        setProducts(res.data.products);
      }
    });
  }, [searchValue]);

  return (
    <div className="navbar">
      <div className="navbar__top">
        {back ? (
          <button className="navbar__back" onClick={() => navigate(-1)}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="icon">
                <path
                  id="Chevron"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.22552 12.5431C6.92483 12.2429 6.92483 11.7579 7.22552 11.4569L14.362 4.32572C14.7961 3.89143 15.5023 3.89143 15.9372 4.32572C16.3713 4.76002 16.3713 5.46488 15.9372 5.89917L9.83231 12.0004L15.9372 18.1C16.3713 18.5351 16.3713 19.24 15.9372 19.6743C15.5023 20.1086 14.7961 20.1086 14.362 19.6743L7.22552 12.5431Z"
                  fill="#F7F7F7"
                />
              </g>
            </svg>
          </button>
        ) : null}
        {!pageName ? (
          <h1 className="logo-text">
            Locaro<span>VITAP</span>
          </h1>
        ) : (
          <h1 className="navbar__page-name">{pageName}</h1>
        )}
        <Link to="/profile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="26"
            viewBox="0 0 22 26"
            fill="none"
          >
            <g id="account_circle">
              <path
                id="Shape"
                d="M11.1408 25L11.1408 24.5H11.1408L11.1408 25ZM1 19.5577L0.500057 19.5502L0.497722 19.705L0.583336 19.8341L1 19.5577ZM21.2817 19.5577L21.6984 19.8341L21.784 19.705L21.7816 19.5502L21.2817 19.5577ZM11.1408 1.5C13.6703 1.5 15.7113 3.54093 15.7113 6.07042H16.7113C16.7113 2.98865 14.2226 0.5 11.1408 0.5V1.5ZM15.7113 6.07042C15.7113 8.59991 13.6703 10.6408 11.1408 10.6408V11.6408C14.2226 11.6408 16.7113 9.1522 16.7113 6.07042H15.7113ZM11.1408 10.6408C8.61134 10.6408 6.57041 8.59991 6.57041 6.07042H5.57041C5.57041 9.1522 8.05906 11.6408 11.1408 11.6408V10.6408ZM6.57041 6.07042C6.57041 3.54093 8.61134 1.5 11.1408 1.5V0.5C8.05906 0.5 5.57041 2.98865 5.57041 6.07042H6.57041ZM11.1408 24.5C7.22971 24.5 3.57865 22.5406 1.41666 19.2813L0.583336 19.8341C2.9306 23.3727 6.89454 25.5 11.1408 25.5L11.1408 24.5ZM1.49994 19.5653C1.50991 18.904 1.84489 18.2784 2.47245 17.6902C3.10211 17.1002 3.99222 16.5826 5.01476 16.1549C7.06188 15.2987 9.51897 14.8521 11.1408 14.8521V13.8521C9.38244 13.8521 6.79445 14.3266 4.6289 15.2324C3.5451 15.6857 2.53697 16.2593 1.78865 16.9606C1.0382 17.6638 0.51544 18.5297 0.500057 19.5502L1.49994 19.5653ZM11.1408 14.8521C12.7541 14.8521 15.2113 15.2986 17.2608 16.155C18.2845 16.5827 19.1762 17.1004 19.8072 17.6906C20.4362 18.2789 20.7718 18.9045 20.7817 19.5653L21.7816 19.5502C21.7662 18.5293 21.242 17.6633 20.4903 16.9603C19.7407 16.2591 18.731 15.6855 17.6463 15.2323C15.4789 14.3267 12.891 13.8521 11.1408 13.8521V14.8521ZM20.865 19.2813C18.703 22.5406 15.052 24.5 11.1408 24.5L11.1408 25.5C15.3872 25.5 19.3511 23.3727 21.6984 19.8341L20.865 19.2813Z"
                fill="#F6F6F6"
              />
            </g>
          </svg>
        </Link>
      </div>
      {search ? (
        <div className="navbar__search">
          <svg
            className="search-icon"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="magnifying-glass-solid 1" clipPath="url(#clip0_1_306)">
              <path
                id="Vector"
                d="M12.1875 6.09375C12.1875 7.43848 11.751 8.68066 11.0156 9.68848L14.7246 13.4004C15.0908 13.7666 15.0908 14.3613 14.7246 14.7275C14.3584 15.0937 13.7637 15.0937 13.3975 14.7275L9.68848 11.0156C8.68066 11.7539 7.43848 12.1875 6.09375 12.1875C2.72754 12.1875 0 9.45996 0 6.09375C0 2.72754 2.72754 0 6.09375 0C9.45996 0 12.1875 2.72754 12.1875 6.09375ZM6.09375 10.3125C6.64776 10.3125 7.19635 10.2034 7.7082 9.99137C8.22004 9.77935 8.68511 9.4686 9.07686 9.07686C9.4686 8.68511 9.77936 8.22004 9.99137 7.7082C10.2034 7.19635 10.3125 6.64776 10.3125 6.09375C10.3125 5.53974 10.2034 4.99115 9.99137 4.4793C9.77936 3.96746 9.4686 3.50239 9.07686 3.11064C8.68511 2.7189 8.22004 2.40815 7.7082 2.19613C7.19635 1.98412 6.64776 1.875 6.09375 1.875C5.53974 1.875 4.99115 1.98412 4.4793 2.19613C3.96746 2.40815 3.50239 2.7189 3.11064 3.11064C2.7189 3.50239 2.40815 3.96746 2.19613 4.4793C1.98412 4.99115 1.875 5.53974 1.875 6.09375C1.875 6.64776 1.98412 7.19635 2.19613 7.7082C2.40815 8.22004 2.7189 8.68511 3.11064 9.07686C3.50239 9.4686 3.96746 9.77935 4.4793 9.99137C4.99115 10.2034 5.53974 10.3125 6.09375 10.3125Z"
                fill="#616060"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_306">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <input
            type="text"
            placeholder="Search for Products"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {products[0] ? (
            <div className="products">
              {products.map((product) => (
                <Link
                  className="product-card"
                  key={product.product_uid}
                  to={`/products/${product.product_uid}`}
                >
                  <span className="text-1-line">{product.product_name}</span>
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Navbar;
