import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";

import "./styles.css";
import SmallProductCard from "../../components/SmallProductCard";
import agent from "../../agent";
import { CartContext } from "../../Context";
import { useLoading } from "../../Utils/useLoading";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";

function CategoryPage() {
  const category = useParams().id;
  const { cart } = useContext(CartContext);
  const [products, setProducts] = useState({});
  const [productsShown, setProductsShown] = useState(10);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const setQuantities = (oldProducts, cartRef) => {
    let tempProducts = { ...oldProducts };

    if (cartRef[0]) {
      for (let shop of cartRef) {
        for (let product of shop.products) {
          if (tempProducts[product.product_uid]) {
            tempProducts[product.product_uid].quantity = product.quantity;
          }
        }
      }
    }

    console.log(tempProducts);
    setProducts(tempProducts);
  };

  useEffect(() => {
    setLoading(true);
    agent.getProducts
      .category(category)
      .then((res) => {
        console.log("Got here", res.data);
        if (res.data.status === "success") {
          agent.getProducts.cart().then((res2) => {
            console.log("Got here 2", res2.data);
            if (res2.data.status === "success") {
              setQuantities(res.data.products, res2.data.cart);
              setLoading(false);
            } else {
              setQuantities(res.data.products, {});
              setLoading(false);
            }
          });
          console.log(res.data);
        } else {
          setLoading(false);
          console.log("error");
          navigate("/");
          floatingMessage("Does not exist", "Category does not exist.", "info");
        }
      })
      .catch((err) => {
        setLoading(false);
        navigate("/");
        floatingMessage("Does not exist", "Category does not exist.", "info");
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (products) {
      setQuantities(products, cart);
    }
  }, [cart]);

  useEffect(() => {
    document.title = `${category} | Locaro"`;
  }, []);

  return (
    <>
      <Navbar pageName={category} back />
      <div className="category-container">
        <div className="category-grid">
          {Object.keys(products)
            .slice(0, productsShown)
            .map((product_uid) => (
              <SmallProductCard
                key={product_uid}
                product={{
                  ...products[product_uid],
                  product_uid,
                }}
              />
            ))}
        </div>
        <button
          className="show-more"
          onClick={() => {
            setProductsShown(productsShown + 20);
          }}
        >
          Show More...
        </button>
      </div>
    </>
  );
}

export default CategoryPage;
