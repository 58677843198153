import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context";

import { floatingMessage } from "../../components/FlashMessage/FlashMessage";
import { useLoading } from "../../Utils/useLoading";

import TextField from "@mui/material/TextField";

import "./styles.css";
import agent from "../../agent";
import { useAuth } from "../../Utils/useAuth";

function LoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { setLoading } = useLoading();

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    setLoading(true);
    agent.auth
      .login(formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          login();
        } else {
          floatingMessage("Login Error", res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        floatingMessage(
          "Login Error",
          "Something went wrong, please try again later."
        );
      });
  };

  useEffect(() => {
    document.title = "Login | Locaro";
  }, []);

  return (
    <>
      <div className="register-login__top-div">
        <Link to="/" className="logo-text">
          Locaro<span>VITAP</span>
        </Link>
        <span className="header">Login</span>
      </div>
      <form className="register-login__forms" onSubmit={submitHandler}>
        <TextField
          variant="outlined"
          label="Student Email"
          name="email"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
        <div style={{ width: "100%", position: "relative" }}>
          <TextField
            variant="outlined"
            label="Password"
            name="password"
            className="locaro-input locaro-box-shadow"
            type="password"
            fullWidth
          />
          <Link className="forgot-password" to="/forgot-password">
            Forgot your password?
          </Link>
        </div>
        <button className="register-login__btn">Login</button>
        <Link to="/register">
          Register
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
              <path
                id="Vector"
                d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                fill="#B90504"
              />
            </g>
            <defs>
              <clipPath id="clip0_3_1143">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </form>
    </>
  );
}

export default LoginPage;
