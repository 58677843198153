import React from "react";
// import loadingGIF from "../Assets/loading.svg";
import { useLoading } from "../Utils/useLoading";

const Loading = () => {
  const { loading } = useLoading();
  return (
    <>
      {loading ? (
        <div className="overlayDiv">
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              height: "80px",
              width: "80px",
            }}
            // {...props}
          >
            <style>
              {
                ".spinner_z9k8{transform-origin:center;animation:spinner_StKS .75s infinite linear}@keyframes spinner_StKS{100%{transform:rotate(360deg)}}"
              }
            </style>
            <path
              d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
              opacity={0.25}
            />
            <path
              style={{ fill: "var(--red)" }}
              d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              className="spinner_z9k8"
            />
          </svg>
        </div>
      ) : null}
    </>
  );
};

export default Loading;
