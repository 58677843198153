import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import agent from "../../agent";
import { useLoading } from "../../Utils/useLoading";

import $ from "jquery";

import "./styles.css";

const OrderPage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    agent.getProducts
      .order(id)
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data);
          setOrder(res.data.orders[0]);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
          navigate("/orders");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        navigate("/orders");
      });
  }, []);

  useEffect(() => {
    if (order.delivery_status === "Accepted") {
      $(".order-details__status").addClass("accepted");
    } else if (order.delivery_status === "Pending") {
      $(".order-details__status").addClass("pending");
    } else if (order.delivery_status === "On Route") {
      $(".order-details__status").addClass("on-route");
    } else if (order.delivery_status === "Declined") {
      $(".order-details__status").addClass("declined");
    }
  }, [order]);

  useEffect(() => {
    document.title = `Order Details | ${order.delivery_status} | Locaro`;
  }, [order]);

  return (
    <>
      <Navbar pageName={"Order Details"} back />
      <div className="order-details-container">
        <div className="order-details locaro-box-shadow">
          <span className="order-details__status">{order.delivery_status}</span>
          <div className="order-details__info">
            <h3>Order ID: {order.delivery_uid}</h3>
            <h4>
              Placed on {dayjs(order.made_on).format("h:mm A - DD-MM-YYYY ")}
            </h4>
            <div className="order-details__lower">
              <h5>{order.hostel}</h5>
              <h6>{order.slot} slot</h6>
            </div>
            <div className="order-details__paid-via">
              <span>Paid via {order.paid_with}</span>
              <span>₹{order.paid}</span>
            </div>
          </div>
        </div>
        <span className="order-details__note">
          More Information coming soon...
        </span>
      </div>
    </>
  );
};

export default OrderPage;
