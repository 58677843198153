import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import TextField from "@mui/material/TextField";

import "./styles.css";
import agent from "../../agent";
import { UserContext } from "../../Context";
import { useLoading } from "../../Utils/useLoading";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";
import { useAuth } from "../../Utils/useAuth";

function RegisterPage() {
  const { login } = useAuth();
  const { setLoading } = useLoading();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otpOverlay, setOtpOverlay] = useState(false);
  const [verified, setVerified] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      password: e.target.password.value,
    };

    setLoading(true);
    agent.auth
      .register(formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          login();
        } else {
          setLoading(false);
          floatingMessage("Registration Error", res.data.message);
          console.log(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        floatingMessage(
          "Registration Error",
          "Something went wrong, please try again later."
        );
        console.log(err);
      });
  };

  const createOtpHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.auth
      .sentOTP(email)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setOtpOverlay(true);
          setLoading(false);
        } else if (res.data.message === "Your Email is already verified!") {
          setLoading(false);
          setVerified(true);
          floatingMessage("Verification Error", res.data.message);
        } else {
          setLoading(false);
          floatingMessage("Verification Error", res.data.message);
          console.log(res.data);
        }
      })
      .catch(() => {
        setLoading(false);
        floatingMessage(
          "Verification Error",
          "Something went wrong, please try again later."
        );
      });
  };

  const verifyOtpHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.auth
      .verifyOTP(email, otp)
      .then((res) => {
        if (res.data.status === "success") {
          setVerified(true);
          setOtpOverlay(false);
          setLoading(false);
        } else {
          setLoading(false);
          floatingMessage("Verification Error", res.data.message);
          console.log(res.data);
        }
      })
      .catch(() => {
        setLoading(false);
        floatingMessage(
          "Verification Error",
          "Something went wrong, please try again later."
        );
      });
  };

  useEffect(() => {
    document.title = "Register an Account | Locaro";
  }, []);

  return (
    <>
      <div className="register-login__top-div">
        <Link to="/" className="logo-text">
          Locaro<span>VITAP</span>
        </Link>
        <span className="header">Register</span>
      </div>
      {otpOverlay ? (
        <>
          <div className="otp-overlay-div">
            <div className="otp-overlay-container">
              <h4 className="otp-top-message">
                Your OTP has been generated on your phone
              </h4>
              <div className="otp-inputs">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="otp-input"
                />
              </div>
              <button
                className="otp-overlay__resend"
                onClick={createOtpHandler}
              >
                Resend OTP
              </button>
              <button
                className="otp-overlay__verify"
                onClick={verifyOtpHandler}
              >
                Verify
              </button>
              <button
                className="otp-overlay__go-back"
                onClick={(e) => {
                  e.preventDefault();
                  setOtpOverlay(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <g clipPath="url(#clip0_609_490)">
                    <path
                      d="M8.10909 13.1191L19.1191 13.1191C19.6691 13.1191 20.1191 12.6691 20.1191 12.1191C20.1191 11.5691 19.6691 11.1191 19.1191 11.1191H8.10909V9.32906C8.10909 8.87906 7.56909 8.65906 7.25909 8.97906L4.4791 11.7691C4.2891 11.9691 4.2891 12.2791 4.4791 12.4791L7.25909 15.2691C7.56909 15.5891 8.10909 15.3591 8.10909 14.9191V13.1191V13.1191Z"
                      fill="#B90504"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_609_490">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="matrix(-1 0 0 -1 24.1191 24.119)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Go back
              </button>
            </div>
          </div>
        </>
      ) : null}
      <form className="register-login__forms" onSubmit={submitHandler}>
        <TextField
          // error
          variant="outlined"
          label="Name"
          name="name"
          className="locaro-input locaro-box-shadow"
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
        />
        <TextField
          variant="outlined"
          label="Student Email"
          name="email"
          className="locaro-input locaro-box-shadow"
          onChange={(newValue) => setEmail(newValue.target.value)}
          InputProps={{
            endAdornment: verified ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="14"
                viewBox="0 0 19 14"
                fill="none"
              >
                <path
                  d="M6.03809 10.8892L1.53383 6.59734L0 8.04854L6.03809 13.8019L19 1.45121L17.477 0L6.03809 10.8892Z"
                  fill="#55D85A"
                />
              </svg>
            ) : (
              <button className="verify-btn" onClick={createOtpHandler}>
                Verify
              </button>
            ),
          }}
          fullWidth
        />
        <TextField
          variant="outlined"
          label="Phone Number"
          name="phone"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
        <TextField
          variant="outlined"
          label="Password"
          name="password"
          type="password"
          autoComplete="on"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
        <button className="register-login__btn">Register</button>
        <Link to="/login">
          Login
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
              <path
                id="Vector"
                d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                fill="#B90504"
              />
            </g>
            <defs>
              <clipPath id="clip0_3_1143">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </form>
    </>
  );
}

// const OTPOverlay = ({ phone_number }) => {
//   return (

//   );
// };

export default RegisterPage;
