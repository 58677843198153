import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";

import "./styles.css";
import imageLink from "../../Utils/Image";

import grocery from "../../assets/Banners/grocery.png";
import electronics from "../../assets/Banners/electronics.png";
import health from "../../assets/Banners/health.png";
import hygiene from "../../assets/Banners/hygiene.png";
import snacks from "../../assets/Banners/snacks.png";
import stationery from "../../assets/Banners/stationery.png";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([
    {
      category_uid: "ca7b63e0-9b90-4136-8604-868edef8944f",
      category_name: "Groceries",
      category_image: "24fa1b03510c72117b41b02c2c019769",
    },
    {
      category_uid: "dbc901b7-cd59-48f5-950e-7b2284e4e6fb",
      category_name: "Snacks & Packaged Foods",
      category_image: "da8d0c7dccd6d6ffcca47245bb4a862a",
    },
    {
      category_uid: "15c8539a-69e9-44c8-a3c5-643a874d79a2",
      category_name: "Stationery",
      category_image: "1e980c73380edac67ed808980bdbbc4d",
    },
    {
      category_uid: "7d6d9346-35df-423b-b16f-3d52f398b45e",
      category_name: "Hygiene & Grooming",
      category_image: "c4e9d4654d7412c7f2d7e979f2b54a0d",
    },
    {
      category_uid: "a5c54ee4-9e80-41f1-80ac-a4f6470f0c8d",
      category_name: "Health & Medicines",
      category_image: "bc36f59ac4a76e28976c877920670b28",
    },
    {
      category_uid: "b6ddbb67-0bf8-4aa8-a6c0-34f4e610408f",
      category_name: "Electronics & Accessories",
      category_image: "5e98fbf9702136b7abccf78fc85da006",
    },
  ]);

  // const [categories, setCategories] = useState([
  //   {
  //     category_uid: "ca7b63e0-9b90-4136-8604-868edef8944f",
  //     category_name: "Groceries",
  //     category_image: grocery,
  //   },
  //   {
  //     category_uid: "dbc901b7-cd59-48f5-950e-7b2284e4e6fb",
  //     category_name: "Snacks & Packaged Foods",
  //     category_image: snacks,
  //   },
  //   {
  //     category_uid: "15c8539a-69e9-44c8-a3c5-643a874d79a2",
  //     category_name: "Stationery",
  //     category_image: stationery,
  //   },
  //   {
  //     category_uid: "7d6d9346-35df-423b-b16f-3d52f398b45e",
  //     category_name: "Hygiene & Grooming",
  //     category_image: hygiene,
  //   },
  //   {
  //     category_uid: "a5c54ee4-9e80-41f1-80ac-a4f6470f0c8d",
  //     category_name: "Health & Medicines",
  //     category_image: health,
  //   },
  //   {
  //     category_uid: "b6ddbb67-0bf8-4aa8-a6c0-34f4e610408f",
  //     category_name: "Electronics & Accessories",
  //     category_image: electronics,
  //   },
  // ]);

  useEffect(() => {
    document.title = "Categories | Locaro";
  }, []);

  return (
    <>
      <Navbar pageName={"Categories"} />
      <div className="categories-container">
        {categories.map((category) => {
          return (
            <Link
              to={`/categories/${category.category_name}`}
              className="category-card-2 locaro-box-shadow"
              // style={{ background: "grey" }}
            >
              <img
                src={imageLink(`/images/category/${category.category_name}`)}
                // src={}
                alt={`${category.category_name}`}
              />
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default CategoriesPage;
