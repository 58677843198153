import React, { createContext, useContext, useEffect, useState } from "react";

const LoadingContext = createContext(false);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Loading: ", loading);
  }, [loading]);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
