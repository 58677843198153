import React from "react";
import agent from "../../agent";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper/modules";

import "swiper/css";
import "swiper/css/grid";
import { useLoading } from "../../Utils/useLoading";
import imageLink from "../../Utils/Image";

const ShopsNearbySlider = () => {
  const [shops, setShops] = React.useState([]);
  const { setLoading } = useLoading();

  React.useEffect(() => {
    setLoading(true);
    agent.shop
      .nearbyShops()
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          console.log(res.data.shops);
          setShops(res.data.shops);
        } else {
          setLoading(false);
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div className="shops-nearby">
      <div className="shops-nearby__header">
        <h3>Shops near you</h3>
        <Link to="/">
          <h4>Explore</h4>
          <div className="arrow-button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="round-arrow_right_alt-24px" clipPath="url(#clip0_3_1143)">
                <path
                  id="Vector"
                  d="M16.01 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13H16.01V14.79C16.01 15.24 16.55 15.46 16.86 15.14L19.64 12.35C19.83 12.15 19.83 11.84 19.64 11.64L16.86 8.85002C16.55 8.53002 16.01 8.76002 16.01 9.20002V11Z"
                  fill="#B90504"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_1143">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Link>
      </div>

      <Swiper
        slidesPerView={2}
        grid={{
          rows: 2,
          fill: "row",
        }}
        spaceBetween={10}
        modules={[Grid]}
        className="shops-nearby__slider"
      >
        {shops[0]
          ? shops.map((shop) => {
              return (
                <SwiperSlide key={shop.shop_uid}>
                  <Link
                    className="shops-nearby__shop-card locaro-box-shadow"
                    style={{
                      backgroundImage: `url(${imageLink(
                        `/images/${shop.shop_image}`
                      )})`,
                    }}
                    to={`/shop/${shop.shop_uid}`}
                  >
                    <span>{shop.shop_name}</span>
                  </Link>
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
    </div>
  );
};

export default ShopsNearbySlider;
