import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "./useLoading";
import { floatingMessage } from "../components/FlashMessage/FlashMessage";

import agent from "../agent";

const AuthContext = createContext({ gotUser: false });

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({ gotUser: false });

  const { setLoading } = useLoading();

  const validate = (navigateBool = true) => {
    setLoading(true);
    agent.auth
      .getUser()
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setUser({ ...res.data.user, gotUser: true });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        // logout();
        if (navigateBool) {
          navigate("/login");
        }
        setLoading(false);
      });
  };

  const login = () => {
    validate();
    navigate("/", { replace: true });
  };

  const logout = () => {
    if (user.gotUser) {
      setLoading(true);
      agent.auth.logout().then((res) => {
        if (res.data.status === "success") {
          console.log("logged out");
          setLoading(false);
        }
      });
    }

    setUser({ gotUser: false });
    navigate("/", { replace: true });
    floatingMessage("Logged Out", "You have been logged out.", "success");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, validate }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
