import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";

import "./styles.css";
import { useAuth } from "../../Utils/useAuth";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../Utils/useLoading";
import { floatingMessage } from "../../components/FlashMessage/FlashMessage";
import agent from "../../agent";

const SettingsPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const changePasswordHandler = (e) => {
    e.preventDefault();
    const old_password = e.target.oldPassword.value;
    const new_password = e.target.newPassword.value;
    setLoading(true);
    agent.home
      .changeProfilePassword(old_password, new_password)
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          floatingMessage("Success", res.data.message, "success");
        } else {
          setLoading(false);
          floatingMessage("Password Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        floatingMessage("Error", "Something went wrong");
      });
  };

  const changePhoneHandler = (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const phone = e.target.phone.value;
    setLoading(true);
    agent.home
      .changeProfilePhone(password, phone)
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          floatingMessage("Success", res.data.message, "success");
        } else {
          setLoading(false);
          floatingMessage("Phone Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        floatingMessage("Error", "Something went wrong");
      });
  };

  useEffect(() => {
    document.title = "Profile Settings | Locaro";
  }, []);

  return (
    <>
      <Navbar pageName={"Profile Settings"} back />

      <div className="settings__top-div">
        <span className="settings__name">{user.full_name}</span>
        <span className="settings__email">{user.email}</span>
      </div>

      <form className="section" onSubmit={changePasswordHandler}>
        <div className="section__header">
          <span className="section__header__text">Change Password</span>
          <button className="section__header__button">Change</button>
        </div>

        <TextField
          variant="outlined"
          label="Current Password"
          type="password"
          name="oldPassword"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />

        <TextField
          variant="outlined"
          label="New Password"
          type="password"
          name="newPassword"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
      </form>
      <form className="section" onSubmit={changePhoneHandler}>
        <div className="section__header">
          <span className="section__header__text">Change Phone Number</span>
          <button className="section__header__button">Update</button>
        </div>

        <TextField
          variant="outlined"
          label="Phone Number"
          name="phone"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />

        <TextField
          variant="outlined"
          label="Password"
          type="password"
          name="password"
          className="locaro-input locaro-box-shadow"
          fullWidth
        />
      </form>
    </>
  );
};

export default SettingsPage;
