import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";

import "./styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import agent from "../../agent";
import { CartContext } from "../../Context";
import { useLoading } from "../../Utils/useLoading";
import imageLink from "../../Utils/Image";

function ProductPage() {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState();
  const { getCart, cart } = useContext(CartContext);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const getQuantity = () => {
    for (let shop of cart) {
      for (let product of shop.products) {
        if (product.product_uid === id) {
          return product.quantity;
        }
      }
    }
    return 0;
  };

  const onAdd = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.cart
      .add(id)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onDecrease = (e) => {
    e.preventDefault();
    setLoading(true);
    agent.cart
      .decrease(id)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    agent.getProducts
      .productInfo(id)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          let tempProduct = res.data.product[0];
          if (cart[0]) {
            const quantity = getQuantity();
            tempProduct.quantity = quantity;
            setLoading(false);
          } else {
            tempProduct.quantity = 0;
            setLoading(false);
          }
          setProductInfo(tempProduct);
          setLoading(false);
        } else {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        navigate("/");
      });
  }, [id]);

  useEffect(() => {
    if (productInfo && cart[0]) {
      const quantity = getQuantity();
      setProductInfo({ ...productInfo, quantity });
    }
  }, [cart]);

  useEffect(() => {
    document.title = `${productInfo?.product_name} | Locaro`;
  }, [productInfo]);

  if (productInfo) {
    return (
      <>
        <Navbar search />
        <div className="product-container">
          <div className="image-div">
            {/* <div className="other-images-div">
              <div className="other-image"></div>
              <div className="other-image"></div>
              <div className="other-image"></div>
              <div className="other-image"></div>
              <div className="other-image"></div>
            </div> */}
            <img
              src={imageLink(
                `/images/products/${productInfo.product_images[0]}`
              )}
            />
          </div>
          <div className="product-info">
            <span className="product-name">{productInfo.product_name}</span>
            <span className="subtext">{productInfo.subtext}</span>
            <Link className="gray-div" to={`/shop/${productInfo.shop_uid}`}>
              <span className="shop-name">{productInfo.shop_name}</span>
              <div className="bottom-div">
                <div className="price-div">
                  <span className="current-price">₹{productInfo.price}</span>
                  {productInfo.product_mrp === productInfo.price ? null : (
                    <>
                      <span className="old-price">
                        ₹{productInfo.product_mrp}
                      </span>
                      <div className="percentage-div">
                        {Math.floor(
                          100 -
                            (productInfo.price * 100) / productInfo.product_mrp
                        )}
                        % off
                      </div>
                    </>
                  )}
                </div>
                {productInfo.quantity === 0 ? (
                  <button className="add-button" onClick={onAdd}>
                    Add
                  </button>
                ) : (
                  <div className="quantity-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      viewBox="0 -960 960 960"
                      width="48"
                      onClick={onDecrease}
                    >
                      <path d="M200-450v-60h560v60H200Z" />
                    </svg>
                    <div>{productInfo.quantity}</div>
                    <svg
                      onClick={onAdd}
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      viewBox="0 -960 960 960"
                      width="48"
                    >
                      <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
                    </svg>
                  </div>
                )}
              </div>
            </Link>
            <span className="description">
              {productInfo.product_description}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default ProductPage;
