import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import SmallProductCard from "../../components/SmallProductCard";

import "./styles.css";
import agent from "../../agent";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../Context";
import { useLoading } from "../../Utils/useLoading";
import imageLink from "../../Utils/Image";

function ShopPage() {
  const { id } = useParams();
  const { cart } = useContext(CartContext);
  const [shopInfo, setShopInfo] = useState();
  const [productsShown, setProductsShown] = useState(10);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const setQuantities = (oldShopInfo, cartRef) => {
    let tempShopInfo = { ...oldShopInfo };

    setLoading(true);
    if (cartRef[0]) {
      for (let shop of cartRef) {
        if (shop.shop_uid === id) {
          for (let product of shop.products) {
            tempShopInfo.shop_inventory[product.product_uid].quantity =
              product.quantity;
          }
        }
      }
    }

    setShopInfo(tempShopInfo);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    agent.shop
      .shopInfo(id)
      .then((res) => {
        if (res.data.status === "success") {
          agent.getProducts.cart().then((res2) => {
            if (res2.data.status === "success") {
              setQuantities(res.data.shop, res2.data.cart);
              setLoading(false);
            } else {
              setQuantities(res.data.shop, []);
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (shopInfo) {
      setQuantities(shopInfo, cart);
    }
  }, [cart]);

  useEffect(() => {
    document.title = `${shopInfo?.shop_name} | Shop | Locaro`;
  }, [shopInfo]);

  return (
    <>
      <Navbar search />
      {shopInfo ? (
        <div className="shop-container">
          <div
            className="shop-image"
            style={{
              backgroundImage: `url(${imageLink(
                `/images/${shopInfo.shop_image}`
              )}`,
            }}
          ></div>
          <div className="shop-details">
            <h1 className="shop-name">{shopInfo.shop_name}</h1>
            <span className="shop-location">{shopInfo.shop_address}</span>
            <span className="shop-phone">+91 {shopInfo.phone_number}</span>
          </div>
          <div className="shop-products">
            <h1>Products</h1>
            <div className="products-grid">
              {Object.keys(shopInfo.shop_inventory)
                .slice(0, productsShown)
                .map((product_uid) => (
                  <SmallProductCard
                    key={product_uid}
                    product={{
                      ...shopInfo.shop_inventory[product_uid],
                      product_uid,
                    }}
                  />
                ))}
            </div>
            <button
              className="show-more"
              onClick={() => {
                setProductsShown(productsShown + 20);
              }}
            >
              Show More...
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ShopPage;
