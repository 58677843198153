import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://demo.vitap.locaro.in/api/vitap"
    : "http://localhost:5100/api/vitap";

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

const auth = {
  register: (registerData) =>
    instance.post("/auth/user/register", registerData),
  login: (loginData) => instance.post("/auth/user/login", loginData),
  logout: () => instance.get("/auth/user/logout"),
  getUser: () => instance.get("/user/info"),
  sentOTP: (email) => instance.post("/auth/user/create-otp", { email }),
  verifyOTP: (email, otp) =>
    instance.post("/auth/user/verify-otp", { email, otp }),
  getResetRequest: (id) => instance.get(`/auth/user/reset-request/${id}`),
  createResetRequest: (email) =>
    instance.post("/auth/user/create-reset-request", { email }),
  changePassword: (id, password) =>
    instance.post("/auth/user/change-password", { reset_uid: id, password }),
};

const home = {
  promotions: () => instance.get("/homepage/promotions"),
  categories: () => instance.get("/search/categories"),
  changeProfilePassword: (old_password, new_password) =>
    instance.put("/user/settings/password", { old_password, new_password }),
  changeProfilePhone: (password, phone) =>
    instance.put("/user/settings/phone", { password, phone }),
};

const getProducts = {
  search: (searchQuery) =>
    instance.get(`/search/product?search=${searchQuery}`),
  category: (id) => instance.get(`/search/categories/${id}`),
  productInfo: (id) => instance.get(`/search/product/${id}`),
  cart: () => instance.get("/cart/cookie/get"),
  orders: () => instance.get("/user/orders"),
  order: (id) => instance.get(`/user/orders/${id}`),
};

const cart = {
  add: (id) => instance.get(`/cart/cookie/set?product_uid=${id}`),
  decrease: (id) =>
    instance.get(`/cart/cookie/set?decrease=true&product_uid=${id}`),
  createPayment: (cart) => instance.post("/payment/create", cart),
  confirmPayment: (response) => instance.post("/payment/confirm", response),
};

const shop = {
  shopInfo: (id) => instance.get(`/public/business/${id}`),
  nearbyShops: () => instance.get("/public/business/nearby"),
};

export default { auth, home, getProducts, shop, cart, instance };
