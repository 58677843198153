import React, { useContext } from "react";
import { CartContext } from "../../Context";
import agent from "../../agent";
import imageLink from "../../Utils/Image";

function CartProductCard({ product }) {
  const { getCart } = useContext(CartContext);

  const onAdd = (e) => {
    e.preventDefault();
    agent.cart
      .add(product.product_uid)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDecrease = (e) => {
    e.preventDefault();
    agent.cart
      .decrease(product.product_uid)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getCart();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="cart-product-card">
      <div className="image-div">
        <img src={imageLink(`/images/products/${product.product_images[0]}`)} />
      </div>
      <div className="text-div">
        <span className="product-name text-2-lines">
          {product.product_name}{" "}
          <span className="subtext">{product.subtext}</span>
        </span>
        <div className="bottom-div">
          <span className="product-price">₹{product.price}</span>
          <div className="quantity-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
              onClick={onDecrease}
            >
              <path d="M200-450v-60h560v60H200Z" />
            </svg>
            <div>{product.quantity}</div>
            <svg
              onClick={onAdd}
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartProductCard;
