import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Navbar from "./components/Navbar";
import TabBar from "./components/TabBar";
import Loading from "./components/Loading";
import CategoriesPage from "./pages/CategoriesPage";
import CategoryPage from "./pages/CategoryPage";
import ProductPage from "./pages/ProductPage";
import ShopPage from "./pages/ShopPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import OrdersPage from "./pages/OrdersPage";
import OrderPage from "./pages/OrderPage";
import AppLayout from "./components/AppLayout";
import { UserContext, CartContext } from "./Context";
import agent from "./agent";
import CartPage from "./pages/CartPage";
import { useLoading } from "./Utils/useLoading";
import Authentication from "./Utils/Authentication";
import { useAuth } from "./Utils/useAuth";

const theme = createTheme({
  palette: {
    primary: { main: "#b90504" },
  },
});

function App() {
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState({});
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { user, validate } = useAuth();

  const getCart = () => {
    setLoading(true);
    agent.getProducts.cart().then((res) => {
      console.log("here", res.data);
      if (res.data.status === "success") {
        setCart(res.data.cart);
        setLoading(false);
        setCartTotal(res.data.total);
      } else {
        setLoading(false);
        setCart([]);
      }
    });
  };

  useEffect(() => {
    validate(false);
    console.log(user);
    getCart();
  }, []);

  return (
    <CartContext.Provider value={{ cart, setCart, getCart, cartTotal }}>
      <ThemeProvider theme={theme}>
        <Loading />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/forgot-password/:id" element={<ResetPasswordPage />} />
          <Route element={<AppLayout />}>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/categories" exact element={<CategoriesPage />} />
            <Route path="/categories/:id" exact element={<CategoryPage />} />
            <Route path="/products/:id" exact element={<ProductPage />} />
            <Route path="/cart" exact element={<CartPage />} />
            <Route path="/shop/:id" exact element={<ShopPage />} />
            <Route path="/profile" exact element={<ProfilePage />} />
            <Route
              path="/settings"
              exact
              element={
                <Authentication>
                  <SettingsPage />
                </Authentication>
              }
            />
            <Route
              path="/orders"
              exact
              element={
                <Authentication>
                  <OrdersPage />
                </Authentication>
              }
            />
            <Route
              path="/order/:id"
              exact
              element={
                <Authentication>
                  <OrderPage />
                </Authentication>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </ThemeProvider>
    </CartContext.Provider>
  );
}

export default App;
