import { easing } from "@mui/material";
import $ from "jquery";

export const floatingMessage = (title, text, type = "error") => {
  //INIT
  let template = $('<div class="floating-message locaro-box-shadow"></div>');
  let msgIcon = $('<i class="fa fa-check"></i>');
  let msgBody = $('<div class="msg-body"></div>');
  let msgTitle = $("<h3></h3>");
  let msgText = $("<p></p>");
  //   let dismissButton = $('<a class="fa fa-times"></a>');

  msgTitle.appendTo(msgBody);
  msgText.appendTo(msgBody);
  msgIcon.appendTo(template);
  msgBody.appendTo(template);
  //   dismissButton.appendTo(template);

  let msgContainer = $('<div id="floating-message-container"></div>');
  msgContainer.appendTo($("body"));

  // Appending
  let msg = template.clone();

  msg.find("h3").text(title);
  msg.find("p").text(text);
  if (type === "error") {
    msg.find("i").addClass("fa-times-circle-o");
    msg.addClass("error");
  } else if (type === "success") {
    msg.find("i").addClass("fa-check-circle-o");
    msg.addClass("success");
  } else {
    msg.find("i").addClass("fa-info-circle");
    msg.addClass("info");
  }
  msg.hide();
  msg.appendTo($("#floating-message-container"));

  msg.slideDown("slow");
  setTimeout(function () {
    msg.slideUp(function () {
      msg.remove();
    });
  }, 3000);
};
